



































import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {DefaultTaskSearchParam} from "@/Config";
import {IdAndName} from "../../models/interfaces/IdAndName";
import {UpdateLogSearchParamModel} from "../../models/UpdateLogSearchParamModel";
import {UpdateLogSearchParam} from "../../models/interfaces/UpdateLogSearchParam";
import moment from "moment";

/**
 * @summary タスクを検索する為のフォームを提供します
 */
@Component({
    components: {
    }
})
export default class UpdateLogSearchForm extends Vue {
    @Prop({default: new UpdateLogSearchParamModel([])}) readonly params!: UpdateLogSearchParam;

    private dates: string[] = [];
    private datesText = "";

    @Watch("dates")
    private datesToText() {
        this.params.updateDateArr = this.dates;

        if (this.params && this.params.updateDateArr && this.params.updateDateArr.length > 0) {
            const date1 = this.params.updateDateArr[0] ? moment(this.params.updateDateArr[0]) : undefined;
            const date2 = this.params.updateDateArr[1] ? moment(this.params.updateDateArr[1]) : undefined;

            let from = "";
            let end = "";
            if (date1 && date2) {
                if (date2.isAfter(date1)) {
                    from = date1.format("YYYY年MM月DD日");
                    end = date2.format("YYYY年MM月DD日");
                }
                else {
                    end = date1.format("YYYY年MM月DD日");
                    from = date2.format("YYYY年MM月DD日");
                }
            }
            else if (date1) {
                from = date1.format("YYYY年MM月DD日");
            }
            else if (date2) {
                from = date2.format("YYYY年MM月DD日");
            }

            if (from) {
                this.datesText = from + "～" + end;
            }

            return this.datesText;
        }

        return "";
    }

    private mounted() {
        if (this.params.updateDateArr) {
            this.dates = this.params.updateDateArr;
            this.datesToText();
        }
    }

    private async reset() {
        await this.$emit("reset");

        if (this.params.updateDateArr && this.params.updateDateArr.length > 0) {
            this.dates = this.params.updateDateArr;
            this.datesToText();
        }
        else {
            this.dates = [];
            this.datesText = "";
        }
    }
}
