import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";
import {UpdateLog} from "@/models/interfaces/UpdateLog";
import {UpdateLogSearchParamModel} from "@/models/UpdateLogSearchParamModel";

/**
 * @summary アップデート履歴に関するStoreを提供します.
 */
@Module({dynamic: true, store, name: "updateLog", namespaced: true})
export class UpdateLogStore extends VuexModule {
    // #region private fields
    private _updateLogs: UpdateLog[] = [];
    private _selectedIds: number[] = [];
    // #endregion

    // #regoin getters
    public get updateLogs(): UpdateLog[] {
        return this._updateLogs;
    }

    public get selectedIds(): number[] {
        return this._selectedIds;
    }
    // #endregion

    // #region mutations
    /**
     * @summary アップデート履歴一覧をセットします
     * @param updateLogs アップデート履歴一覧
     */
    @Mutation
    public setUpdateLogs(updateLogs: UpdateLog[]): void {
        this._updateLogs = updateLogs;
    }

    /**
     * @summary アップデート履歴を選択状態にします
     * @param {number} updateLogId アップデート履歴ID
     */
    @Mutation
    public selectBy(updateLogId: number): void {
        this._selectedIds = [updateLogId];
    }

    /**
     * @summary 選択状態のアップデート履歴を追加します
     * @param {number} updateLogId アップデート履歴ID
     */
    @Mutation
    public addSelectBy(updateLogId: number): void {
        if (this._selectedIds.indexOf(updateLogId) >= 0) {
            return;
        }

        this._selectedIds.push(updateLogId);
    }

    /**
     * @summary アップデート履歴を未選択状態にします
     * @param {number} updateLogId アップデート履歴ID
     */
    @Mutation
    public unselectBy(updateLogId: number): void {
        this._selectedIds = this._selectedIds.filter(selectedId => selectedId !== updateLogId);
    }
    // #endregion

    // #regoin
    /**
     * @summary アップデート履歴をサーバーから取り込みます.
     */
    @Action
    public async fetchUpdateLogs(searchParam: UpdateLogSearchParamModel): Promise<boolean> {
        try {
            const response = await axios.get<UpdateLog[]>("api/update_log" + searchParam.getQueryString());
            this.setUpdateLogs(response.data);
            return true;
        }
        catch (ex) {
            console.error("アップデート履歴の取り込みに失敗しました。", ex);
        }
        return false;
    }

    /**
     * @summary アップデート履歴を新規追加します.
     * @param updateLog 新規追加するアップデート履歴
     */
    @Action
    public async addUpdateLog(updateLog: UpdateLog): Promise<boolean> {
        try {
            const response = await axios.post<UpdateLog>("api/update_log", updateLog);
            this.updateLogs.push(response.data);
            return true;
        }
        catch (ex) {
            console.error("アップデート履歴の新規登録に失敗しました。", ex);
        }
        return false;
    }

    /**
     * @summary アップデート履歴を保存します.
     * @param updateLog 保存するアップデート履歴
     */
    @Action
    public async saveUpdateLog(updateLog: UpdateLog): Promise<boolean> {
        try {
            const response = await axios.put<UpdateLog>("api/update_log/" + updateLog.updateLogId, updateLog);
            return true;
        }
        catch {
            console.error("アップデート履歴の更新に失敗しました。");
        }
        return false;
    }

    /**
     * @summary アップデート履歴を保存します.
     * @param updateLog 保存するアップデート履歴
     */
    @Action
    public async deleteUpdateLog(updateLog: UpdateLog): Promise<boolean> {
        try {
            const response = await axios.delete("api/update_log/" + updateLog.updateLogId);
            return true;
        }
        catch (ex) {
            console.error("アップデート履歴の削除に失敗しました。");
        }
        return false;
    }
}

export const UpdateLogStoreModule = getModule(UpdateLogStore);
