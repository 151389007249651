import {UpdateLog} from "@/models/interfaces/UpdateLog";

export class UpdateLogModel implements UpdateLog {
    sheetId = 0;
    updateLogId = 0;
    updateDate = "";
    systemId = 0;
    category = "";
    detail = "";
    newMenu = "";
    stageUpdateDate = "";
    stageUpdatePersonId = 0;
    publicUpdateDate = "";
    publicUpdatePersonId = 0;
    confirmDate = "";
    confirmPersonId = 0;
    public constructor() {
        const date = new Date();
        this.updateDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
}
