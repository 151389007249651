











































































































































































































import {Component, Vue} from "vue-property-decorator";
import * as CONFIG from "@/Config";
import NotifySnackBar from "@/components/Atoms/NotifySnackBar.vue";
import {MasterInfoStoreModule, MasterInfoStore} from "@/stores/MasterInfoStore";
import {UpdateLogStore, UpdateLogStoreModule} from "@/stores/UpdateLogStore";
import TableSelectableItem from "@/components/Atoms/TableSelectableItem.vue";
import TableTextFieldDialog from "@/components/Atoms/TableTextFieldDialog.vue";
import TableTextareaDialog from "@/components/Atoms/TableTextareaDialog.vue";
import DeleteDialog from "@/components/Atoms/DeleteDialog.vue";
import NameById from "@/components/Atoms/NameById.vue";
import {UpdateLog} from "@/models/interfaces/UpdateLog";
import AddUpdateLogDialog from "@/components/Organisms/AddUpdateLogDialog.vue";
import {UpdateLogModel} from "@/models/UpdateLogModel";
import {SheetStore, SheetStoreModule} from "../../stores/SheetStore";
import {IdAndName} from "../../models/interfaces/IdAndName";
import UpdateLogSearchForm from "@/components/Organisms/UpdateLogSearchForm.vue";
import {UpdateLogSearchParamModel} from "../../models/UpdateLogSearchParamModel";
import TableDatePickerDialog from "../Atoms/TableDatePickerDialog.vue";
import {VDataTableOptions} from "../../models/interfaces/VDataTableOptions";
/**
 * @summary アップデート履歴を一覧表示するためのページを提供します.
 */
@Component({
    components: {
        DeleteDialog,
        NotifySnackBar,
        TableSelectableItem,
        TableTextFieldDialog,
        TableTextareaDialog,
        TableDatePickerDialog,
        NameById,
        AddUpdateLogDialog,
        UpdateLogSearchForm
    }
})
export default class UpdateLogPage extends Vue {
    // #region private fields
    private headers = [
        {text: "No", value: "updateLogId", width: 72},
        {text: "アップデート日", value: "updateDate", width: 100},
        {text: "システム", value: "systemId", width: 100},
        {text: "大項目", value: "category", width: 100},
        {text: "アップデート内容", value: "detail", sortable: false, width: 240},
        {text: "新メニュー", value: "newMenu", width: 80},
        {text: "stage反映日", value: "stageUpdateDate", width: 100},
        {text: "stage反映者", value: "stageUpdatePersonId", width: 100},
        {text: "system反映日", value: "publicUpdateDate", width: 100},
        {text: "system反映者", value: "publicUpdatePersonId", width: 100},
        {text: "確認日", value: "confirmDate", width: 100},
        {text: "確認者", value: "confirmPersonId", width: 100},
        {text: "", value: "action2", sortable: false, width: 40}
    ];
    private tableHeight = 0;
    private isLoading = false;
    private sortBy: string[] = [];
    private sortDesc: boolean[] = [];
    private isShowSearchPanel = false;
    private searchParams: UpdateLogSearchParamModel = CONFIG.DefaultUpdateLogSearchParam();
    // #endregion

    // #region private properties
    /**
     * @summary アップデート履歴のストア情報
     */
    private get updateLogStore(): UpdateLogStore {
        return UpdateLogStoreModule;
    }

    /**
     * @summary マスタ情報ストア
     */
    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    /**
     * @summary シートのストア情報
     */
    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }
    // #endregion

    // #region private methods
    private async created(): Promise<void> {
        const json = localStorage.getItem("sortBy_updateLog");
        if (json) {
            this.sortBy = JSON.parse(json);
        }

        const sortDescJson = localStorage.getItem("sortDesc_updateLog");
        if (sortDescJson) {
            this.sortDesc = JSON.parse(sortDescJson);
        }

        window.addEventListener("resize", (e) => {
            this.tableHeight = window.innerHeight - 128;
        });
        this.tableHeight = window.innerHeight - 128;

        this.isLoading = true;

        const param = CONFIG.DefaultUpdateLogSearchParam();
        param.sheetId = this.sheetStore.currentSheetId;
        await this.updateLogStore.fetchUpdateLogs(param);
        this.isLoading = false;
    }

    private async submitSearch(): Promise<void> {
        this.isLoading = true;
        this.searchParams.sheetId = this.sheetStore.currentSheetId;
        await this.updateLogStore.fetchUpdateLogs(this.searchParams);
        this.isLoading = false;
    }

    private resetSearchDialog(): void {
        this.searchParams = CONFIG.DefaultUpdateLogSearchParam();
    }

    /**
     * @summary アップデート履歴を新規作成します.
     */
    private async addUpdateLog(): Promise<void> {
        const dialog = this.$refs.addUpdateLogDialog as AddUpdateLogDialog;
        if (!dialog) {
            return;
        }

        const updateLog = await dialog.showAsync(new UpdateLogModel());
        if (updateLog) {
            if (this.sheetStore.currentSheetId) {
                updateLog.sheetId = this.sheetStore.currentSheetId;
            }

            this.isLoading = true;
            const isSuccess = await this.updateLogStore.addUpdateLog(updateLog);
            this.isLoading = false;
            if (isSuccess) {
                this.showNotifyMessage("追加しました", "success");
                return;
            }
            this.showNotifyMessage("追加に失敗しました", "error");
            return;
        }
        this.showNotifyMessage("キャンセルしました", "warning");
    }

    /**
     * @summary アップデート履歴を更新します.
     * @param 更新するアップデート履歴
     */
    private async saveUpdateLog(updateLog: UpdateLog): Promise<void> {
        if (this.sheetStore.currentSheetId) {
            updateLog.sheetId = this.sheetStore.currentSheetId;
        }

        this.isLoading = true;
        const isSuccess = await this.updateLogStore.saveUpdateLog(updateLog);
        if (!isSuccess) {
            this.showNotifyMessage("更新に失敗しました", "error");
            this.isLoading = false;
            return;
        }
        this.showNotifyMessage("更新しました", "success");
        this.searchParams.sheetId = this.sheetStore.currentSheetId;
        await this.updateLogStore.fetchUpdateLogs(this.searchParams);
        this.isLoading = false;
    }

    /**
     * @summary アップデート履歴を削除します.
     * @param 削除するアップデート履歴
     */
    private async deleteUpdateLog(updateLog: UpdateLog): Promise<void> {
        const deleteDialog = this.$refs.deleteDialog as DeleteDialog;
        if (!deleteDialog) return;
        const isDelete = await deleteDialog.showAsync(`No.${updateLog.updateLogId}`, "を削除しますか？");
        if (!isDelete) return;

        this.isLoading = true;
        await this.updateLogStore.deleteUpdateLog(updateLog);

        this.searchParams.sheetId = this.sheetStore.currentSheetId;
        await this.updateLogStore.fetchUpdateLogs(this.searchParams);
        this.isLoading = false;
    }

    private onUpdateOptions(options: Partial<VDataTableOptions>): void {
        this.sortBy = [];
        if (options.sortBy) {
            this.sortBy = options.sortBy;
            localStorage.setItem("sortBy_updateLog", JSON.stringify(options.sortBy));
        }

        this.sortDesc = [];
        if (options.sortDesc) {
            this.sortDesc = options.sortDesc;
            localStorage.setItem("sortDesc_updateLog", JSON.stringify(options.sortDesc));
        }
    }

    /**
     * @summary 通知メッセージを表示します.
     * @param message メッセージ
     * @param color 通知バーの色
     */
    private showNotifyMessage(message: string, color: string): void {
        const notifySnackBar = this.$refs.notifySnackBar as NotifySnackBar;
        if (!notifySnackBar) {
            return;
        }
        notifySnackBar.show(message, color, 3000);
    }
    // #endregion

    // #region dialogs
    private async openDatePickerDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.datePickerDialog as TableDatePickerDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }
        return result;
    }

    private async openTextFieldDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.textFieldDialog as TableTextFieldDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }

        return result;
    }

    private async openTextareaDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.textAreaDialog as TableTextareaDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }

        return result;
    }

    private async openSelectableItem(event: MouseEvent, items: {[key: number]: IdAndName}, values?: {id: number, name: string}[]): Promise<number | undefined> {
        const dialog = this.$refs.selectableItem as TableSelectableItem;
        if (!dialog) {
            return undefined;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return undefined;
        }

        if (!items) {
            items = [{id: 0, name: ""}];
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(items, rect.left, rect.top, values);
        if (!result) {
            throw new Error("input cancel");
        }

        return result;
    }

    private select(updateLogId: number): void {
        if (this.updateLogStore.selectedIds.indexOf(updateLogId) >= 0) {
            this.updateLogStore.unselectBy(updateLogId);
        }
        else {
            this.updateLogStore.selectBy(updateLogId);
        }
    }
    // #endregion
}
