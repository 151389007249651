


















































































































































































































import {Component, Vue} from "vue-property-decorator";
import TableSelectableItem from "@/components/Atoms/TableSelectableItem.vue";
import {ResponseLog} from "@/models/interfaces/ResponseLog";
import {ResponseLogModel} from "../../models/ResponseLogModel";
import {MasterInfoStore, MasterInfoStoreModule} from "../../stores/MasterInfoStore";
import {UpdateLog} from "@/models/interfaces/UpdateLog";
import {UpdateLogModel} from "../../models/UpdateLogModel";
import {SheetStore, SheetStoreModule} from "../../stores/SheetStore";

/**
 * @summary アップデート履歴を新規追加するためのダイアログを提供します.
 */
@Component({
    components: {
        TableSelectableItem
    }
})
export default class AddUpdateLogDialog extends Vue {
    private resolve?: (value: UpdateLog | undefined | PromiseLike<UpdateLog | undefined>) => void = undefined;
    private updateLog: UpdateLog = new UpdateLogModel();
    private isOpend = false;

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    /**
     * @summary 対応ログを追加するためのダイアログを開きます.
     * @param updateLog 対応ログ
     * @returns 追加する内容
     */
    public showAsync(updateLog: UpdateLog): Promise<UpdateLog | undefined> {
        this.isOpend = true;
        this.updateLog = updateLog;
        return new Promise<UpdateLog | undefined>(resolve => {
            this.resolve = resolve;
        });
    }

    /**
     * @summary キャンセルしてダイアログを閉じます.
     */
    private cancel(): void {
        this.isOpend = false;
        this.resolve!(undefined);
    }

    /**
     * @summary ダイアログを閉じて追加する対応ログを取得します.
     */
    private ok() {
        this.isOpend = false;
        if (this.sheetStore.currentSheetId) {
            this.updateLog.sheetId = this.sheetStore.currentSheetId;
        }
        this.resolve!(this.updateLog);
    }

    private toArray<TValue>(dictionary: {[key: number]: TValue}): TValue[] {
        const array = [];
        for (const key in dictionary) {
            array.push(dictionary[key]);
        }
        return array;
    }
}
